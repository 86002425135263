// import { CvPage } from '../cv/cv'

// export default CvPage

import React from 'react';
import { Layout } from '../components/layout';
import { Head } from '../components/head';

const IndexPage = () => {
  return (
    <Layout>
      <Head />
    </Layout>
  )
}

export default IndexPage
